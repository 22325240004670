:root {
  --header-height: 100px;
}

@media (max-width: 840px) {
  :root {
    --header-height: 70px;
  }
}

.app {

}

/* Header */

.app > header {
  position: sticky;
  top: 0;
  height: var(--header-height);
  z-index: 2;
  background: #fff;
  box-shadow: none;
  transition: box-shadow .21s;
}

@media (prefers-color-scheme: dark) {
  .app > header {
    background: #000;
  }
}

.app > header[class*='is-box-shadowed'] {
  box-shadow: rgb(149 157 165 / 10%) 0 8px 24px;
}

@media (prefers-color-scheme: dark) {
  .app > header[class*='is-box-shadowed'] {
    box-shadow: none;
    border-bottom: 1px solid var(--black-color-1000)
  }
}

.app > header > [class='container'] {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 0 20px;
}

/* Header logo */
.app > header .header-logo {
  height: 50px;
  width: auto
}

@media (max-width: 840px) {
  .app > header .header-logo {
    height: 40px
  }
}

/* Header header */
.app > header h1 {
  margin-top: 0;
  margin-left: 20px;
  font-size: 16pt;
}

/* Header navigation */
.app > header > [class='container'] > nav {
  display: flex;
  justify-content: center;
  flex: 1
}

@media (max-width: 840px) {
  .app > header > [class='container'] > nav {
    display: none;
  }
}

.app > header > [class='container'] > nav > a {
  display: block;
  color: #000;
  font-weight: bold;
  padding: 10px 15px
}

@media (prefers-color-scheme: dark) {
  .app > header > [class='container'] > nav > a {
    color: #fff
  }
}

.app > header > [class='container'] > nav > a:hover {
  color: var(--accent-color-100)
}

/* Header right button */

@media (max-width: 840px) {
  .header-action-button {
    display: none;
  }
}

/* Header menu button */

.header-menu-button {
  transform: scale(1.5)
}

@media (min-width: 840px) {
  .header-menu-button {
    display: none;
  }
}

/* Block common styles */

.app > [class*='block'] {
  padding: 50px 30px;
  scroll-margin-top: var(--header-height);
}

/* Footer */

.app > footer {
  margin-top: 50px;
  font-size: 10pt;
  color: var(--black-color-80)
}

.app > footer > [class*='container'] {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  border-top: 1px solid var(--black-color-20);
  padding: 20px;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .app > footer > [class*='container'] {
    border-color: var(--black-color-800)
  }
}

@media (max-width: 700px) {
  .app > footer > [class*='container'] {
    justify-content: center;
  }
}

.app > footer > [class*='container'] > div > svg {
  display: inline-block;
  width: 16px;
  fill: red;
  vertical-align: middle;
}

/* Mobile aside */

.mobile-aside {
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 101;
  background: #fff;
  box-shadow: rgb(0 0 0 / 16%) 0 10px 36px 0, rgb(0 0 0 / 6%) 0 0 0 1px;
  transition: .32s;
  display: flex;
  flex-direction: column;
  padding: 20px
}

@media (prefers-color-scheme: dark) {
  .mobile-aside {
    background: #000;
    box-shadow: none;
    border-left: 1px solid var(--black-color-1000)
  }
}

.mobile-aside:not([class*='is-shown']) {
  transform: translateX(100%);
  visibility: hidden;
}

.mobile-aside > nav > a {
  display: block;
  color: #000;
  font-weight: bold;
  padding: 15px 0;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .mobile-aside > nav > a {
    color: #fff
  }
}

/* Aside darker */
.aside-darker {
  position: fixed;
  background: rgb(0 0 0 / 30%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(10px);
  visibility: visible;
  transition: .32s;
}

.aside-darker:not([class*='is-shown']) {
  opacity: 0;
  backdrop-filter: blur(0);
  visibility: hidden;
}

/* Contacts */
.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

@media (max-width: 980px) {
  .contacts {
    margin-top: 20px
  }
}

.contacts > a {
  display: flex;
  align-items: center;
  gap: 0 15px;
  color: #000;
  font-size: 14pt;
}

@media (prefers-color-scheme: dark) {
  .contacts > a {
    color: #fff
  }
}

@media (max-width: 980px) {
  .contacts > a {
    font-size: 12pt;
    gap: 0 10px
  }
}

@media (max-width: 780px) {
  .contacts > a {
    justify-content: center;
    margin-top: 30px;
    font-size: 14pt;
    gap: 0 15px;
  }
}

.contacts > a:not(:first-child) {
  margin-top: 20px;
}

.contacts > a > svg {
  width: 35px
}

@media (prefers-color-scheme: dark) {
  .contacts > a > svg {
    fill: #fff
  }
}

@media (max-width: 980px) {
  .contacts > a > svg {
    width: 25px
  }
}
