.loader {
  border-radius: 50%;
  border-style: solid;
  border-right-color: transparent !important;
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
