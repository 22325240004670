.booking-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.booking-page > h1 {
  text-align: center;
}

@media (max-width: 520px) {
  .booking-page h2 {
    text-align: center;
  }
}

.fill-form-text {
  margin-top: 10px;
}

@media (max-width: 520px) {
  .fill-form-text {
    display: none;
  }
}

.booking-page > form {
  margin: 100px auto;
  max-width: 680px;
}

@media (max-width: 520px) {
  .booking-page > form {
    margin: 30px auto;
  }
}

/* Agreement */
.agreement {
  margin-top: 30px;
}

.agreement > h2 {
  text-align: center;
}

.agreement-canvas {
  margin-top: 50px;
  padding: 20px;
  border: 1px solid var(--black-color-30);
  border-radius: 12px;
}

@media (prefers-color-scheme: dark) {
  .agreement-canvas {
    border-color: var(--black-color-900)
  }
}

.agreement-button-container {
  padding: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
}

@media (prefers-color-scheme: dark) {
  .agreement-button-container {
    background: #000;
  }
}

/* Congrats */

.congrats {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  text-align: center;
}

.congrats > svg {
  width: 150px
}

@media (prefers-color-scheme: dark) {
  .congrats > svg {
    fill: #fff
  }
}

.congrats > [class='title'] {
  margin-top: 30px;
  font-weight: bold;
  font-size: 16pt
}

.congrats > [class='description'] {
  margin-top: 15px;
}

/* Contacts */
.contacts {
  margin-top: 30px;
  text-align: center;
}

@media (max-width: 980px) {
  .contacts {
    margin-top: 20px
  }
}

.contacts > a {
  display: flex;
  align-items: center;
  gap: 0 15px;
  color: #000;
  font-size: 14pt;
}

@media (prefers-color-scheme: dark) {
  .contacts > a {
    color: #fff;
    justify-content: center;
  }
}

@media (max-width: 980px) {
  .contacts > a {
    font-size: 12pt;
    gap: 0 10px
  }
}

@media (max-width: 780px) {
  .contacts > a {
    margin-top: 30px;
    font-size: 14pt;
    gap: 0 15px;
  }
}

.contacts > a:not(:first-child) {
  margin-top: 20px;
}

.contacts > a > svg {
  width: 35px
}

@media (prefers-color-scheme: dark) {
  .contacts > a > svg {
    fill: #fff
  }
}

@media (max-width: 980px) {
  .contacts > a > svg {
    width: 25px
  }
}
