.container {
  margin: auto;
  width: 100%;
  max-width: 1260px;
  padding: 0 30px
}

.page {
  margin-top: 30px;
}

/* Data rows */

.data-rows:not(:first-child) {
  margin-top: 20px;
}

.data-rows > .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px 10px;
  padding: 15px 0;
  border-bottom: 1px dashed var(--black-color-30);
  user-select: text;
}

@media (prefers-color-scheme: dark) {
  .data-rows > .row {
    border-bottom-color: var(--black-color-900);
  }
}

.data-rows > .row > div {
  word-break: break-word;
}

.data-rows > .row > div:first-child {
  font-weight: bold;
}

/* Loader container */

.loader-container {
  display: flex;
  justify-content: center;
  padding: 50px;
}

/* Flexes */

.flex {
  display: flex;
}

.flex-aic {
  align-items: center;
}

.flex-jce {
  justify-content: flex-end;
}
