.select {
  width: 100%;
  flex: 1;
  min-width: 180px;
}

.is-auto-width {
  width: auto;
  flex: unset;
}

.selected-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--inputs-horizontal-padding);
  font-weight: bold;
  cursor: pointer;
  border-radius: 12px;
  height: var(--input-base-height);
  padding: 0 var(--inputs-horizontal-padding);
  background: var(--black-color-10);
}

@media (prefers-color-scheme: dark) {
  .selected-option {
    background: var(--black-color-900);
  }
}

.selected-option > svg {
  width: 16px;
}

@media (prefers-color-scheme: dark) {
  .selected-option > svg {
    fill: #fff;
  }
}
